/** @format */

.custom-tabs {
  .tabs-root {
    border-bottom: 0.6px solid #d5d5d6;
    border-radius: 5px 5px 0px 0px;

    min-height: fit-content;
  }

  .tab-scroller {
    height: fit-content;
  }

  .tab-label {
    min-height: auto;
    padding-bottom: 9px;

    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #3e4685;

    text-transform: none;
  }

  .tab-indicator {
    height: 3px;
    background-color: #3e4685;
  }

  .tab-inactive {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.01em;

    /* Secondary Color */

    color: #9bb4cc;
  }
}
