/** @format */

.custom-titration-calender {
  .rct-dateHeader {
    width: 30px;
  }

  .rct-vl {
    background: transparent !important;
    // border: none !important;

    width: 30px;
  }

  .rct-day-0 {
    border-left: 0.2px solid #0b0b0b !important;
    opacity: 0.4;
  }

  .next-review-date {
    border-left: 3px solid #d80808 !important;
  }

  .rct-day-0.next-review-date {
    border-left: 3px solid #d80808 !important;
    opacity: 1;
  }

  .rct-header-root {
    background: #f1f2f3;
    border-bottom: none;
  }

  .rct-calendar-header {
    border-width: 0.6px 0.6px 0.6px 0px;
    border-style: solid;
    border-color: #d5d5d6;

    .rct-dateHeader {
      background: #f1f2f3;
      border-width: 0px 0.5px 0.5px 0px;
      border-style: solid;
      border-color: #d5d5d6;

      font-family: 'DM Sans', sans-serif;
      font-style: normal;
      font-weight: 700;

      color: #5c6a90;
    }
  }

  .timeline-header {
    background: #f1f2f3;

    .sidebar-title {
      display: flex;
      justify-content: center;
      align-items: center;

      border: 0.6px solid #d5d5d6;

      font-family: 'DM Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #5c6a90 !important;
    }
  }

  .rct-sidebar {
    background: #f5f9fe !important;
    box-shadow: 10px 0px 23px rgba(0, 0, 0, 0.09);

    .rct-sidebar-row-odd {
      background: inherit;
    }

    .group-container {
      padding: 10px 10px 16px 10px;

      .group-title {
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #5c6a90;
      }

      .group-subtitle {
        padding-top: 8px;

        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;

        /* Secondary Text Color */

        color: #5c6a90;
      }
    }
  }

  .rct-horizontal-lines {
    background-color: #fafbfc;
    .rct-hl-odd,
    .rct-hl-even {
      background-color: inherit;
      border-bottom: 0.5px solid #0b0b0b;
    }
  }
}
