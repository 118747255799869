/** @format */

.datePickerInput {
  width: 120px;
  margin-right: 25px;
  outline: none;
  border: none;
  background-color: inherit;
  // color: #9bb4cc;
  // border:'12px solid red';
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
