/** @format */

#na-linechart-parent {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  height: auto;
  min-width: 100;

  color: #3e4685;
  background-color: white;
  padding: 10px;
}

#weight-change {
  inherit: none;

  /* Font */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #3e4685;
  /* Font */

  box-sizing: border-box;

  height: auto;
  min-width: 433px;
  border-radius: 5px;

  padding: 10px;

  background: #fafbfc;

  border: 1px solid #d5d5d6;

  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .weight-change-container {
    .weight-change-items {
      display: flex;
      .weight-change-dry-weight {
        box-sizing: border-box;

        display: flex;
        // flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        gap: 10px;

        margin-bottom: 9px;

        background: #f5f9fe;

        border: 1px solid #9bb4cc;
        border-radius: 5px;
      }

      .weight-change-vital-data {
        display: flex;
        flex-direction: column;

        // padding: 5px 0 20px 0;
        padding: 10px 20px;
        // padding: 14px 0px 0px 8px;

        // align-items: center;
        // justify-content: space-between;

        width: 290px;
        height: 40px;

        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        letter-spacing: 0.01em;

        color: #5c6a90;
      }
    }

    .vital-data-weight-change-2-days {
      // margin-left: 10px;
      word-wrap: break-word;
    }

    .vital-data-weight-change-7-days {
      margin-left: 10px;
    }

    #trendline-chart-weight {
      background: #f5f9fe;

      width: 410px;
      height: 240px;
      border: 0.5px solid #9bb4cc;
      border-radius: 2px;

      .svg-element {
        .focus-element {
          // transform: translate(40px, 10px);
        }
      }
      .axis--x {
        // display: none;
      }

      .axis--y {
        // display: none;
      }
    }
  }
}
