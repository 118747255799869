/** @format */

#treament-advice-container {
  .ag-header-cell-text {
    color: #3e4685;

    font-family: '"DM Sans", sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.063rem;
    letter-spacing: 0.01em;
  }
}

div[class*='-menu'] {
  min-width: 100%;
  width: max-content;
  max-width: 500px;
}
