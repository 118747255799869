/** @format */

.datepicker-notes {
  .datepicker-main {
    border: 1px solid #667185;
    border-radius: 5px;

    .datepicker-item {
      border: none;
      height: 56px;
      padding: 10px;

      input {
        color: #3b4961;
      }

      svg {
        display: none;
      }
    }
  }
}
