/** @format */

#patient-prescription-section-container {
  #prescription-container {
    .custom-table {
      .table-head-row {
        th {
          font-size: 14px;
          font-weight: 600;
        }
      }

      div[class*='-indicatorContainer'] {
        width: 12px;
        height: 12px;
      }

      td {
        padding: 0px;
        border: solid 1px #9bb4cc;
        border-style: none solid solid none;

        &.table-body-cell-no-data {
          border-style: solid;
          border-radius: 5px;
        }
      }

      tr:first-child td:first-child {
        border-top-left-radius: 4px;
      }

      tr:first-child td:last-child {
        border-top-right-radius: 4px;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
      }

      tr:first-child td {
        border-top-style: solid;
      }
      tr td:first-child {
        border-left-style: solid;
      }

      tr:last-child td {
        border-bottom-style: solid;
      }
      tr td:last-child {
        border-bottom-style: solid;
      }
    }
  }

  #presciption-approval {
    .custom-table {
      .table-head-row {
        th {
          color: #667185;
          font-size: 14px;
          font-weight: 400;
          font-family: 'Lato';
          padding-left: 8px;
          background-color: #d5d5d6;
        }
      }

      .table-body-cell-no-data {
        border-radius: 0px;
        border-left: 0px;
        border-right: 0px;
      }
    }
  }

  .custom-table {
    .table-head-row {
      th {
        color: #33425b;
        font-size: 16px;
        font-weight: 600;

        border-bottom: 0px;

        padding-left: 3px;
      }
    }

    .full-row {
      height: 32px;
    }

    table {
      border-collapse: separate;
      border-spacing: 1;
    }

    td {
      padding: 0px;
      padding-left: 8px;
    }
  }
}
