/** @format */

#na-linechart-parent {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  height: auto;
  min-width: 100;

  color: #3e4685;
  background-color: white;
  padding: 10px;
}

#linechart-parent {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  height: auto;
  min-width: 420px;

  color: #3e4685;
  background-color: white;
  padding: 10px;

  border: 1px solid #d5d5d6;

  .line-chart-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    word-break: unset;

    color: '#3E4685';

    margin-bottom: 8px;
  }

  .data-trendline-div {
    gap: 10px;

    .vital-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      gap: 5px;
    }

    .vital-data > div {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      height: 21px;
      width: 50px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: white;
      color: #3e4685;
      border-color: #3e4685;
      border-radius: 13px;
      border-width: 0.5px;
    }

    #trendline-chart {
      /* &::before {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;

        color: #5c6a90;

        content: 'Trendline';
      } */

      background: #eceff4;

      height: 230px;
      width: 395px;

      padding: 0.5rem;

      border: 0.5px solid #d5d5d6;
      border-radius: 2px;

      .svg-element {
        .focus-element {
          transform: translate(30px, 5px);
        }
      }

      .axis--x {
        // display: none;
      }

      .axis--y {
        // display: none;
      }
    }
  }
}
