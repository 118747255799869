/** @format */

// .react-tel-input {
//   // background-color: red;
//   border-radius: 5px;
// }

// .selected-flag {
//   background-color: #ffff;
//   display: flex;
//   align-items: center;
//   border: none;
//   padding: 10px !important;
// }

// .flag-dropdown {
//   // background-color: red;
//   border: none;
//   padding: 20px;
// }

.form-control {
  width: 100%;
  border: 1px solid #667185 !important;
  // height: 50px !important;
}

.react-tel-input {
  border: none !important;
  background-color: #ffff;
}

.flag-dropdown {
  border: none !important;
  background-color: transparent !important;
  border-left: 1px solid #667185 !important;
  border-top: 1px solid #667185 !important;
  border-bottom: 1px solid #667185 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
