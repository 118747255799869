/** @format */
.appointment-type-grid-select-input-field > div > div > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.date-grid-input-field > div > div > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.start-time-grid-input-field > div > div > div,
.end-time-grid-input-field > div > div > div {
  width: max-content;

  display: grid;
  grid-template-columns: 2fr 1fr;
}

.appointment-place-grid-input-field > div > div > div {
  display: grid;
  grid-template-columns: 1fr 1fr;

  div:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.is-lab-test-grid-checkbox-field > div > div > div {
  width: fit-content;

  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: rtl;
}

// .css-zow5z4-MuiGrid-root > .MuiGrid-item:nth-child(1) {
//   padding-top: 24px;
// }

// .css-zow5z4-MuiGrid-root > .MuiGrid-item {
//   padding-top: 0px;
// }

.days-before-consultation-grid-select-input-field > div > div > div {
  margin-left: -07px;
}

.notes-grid-custom-notes > div > div > div {
  min-width: 400px;
}

.custom-appointment-notes {
  margin-left: 10px;
}
