/** @format */

.datepicker-main {
  display: flex;
  align-items: center;
}

.datepicker-item {
  display: flex;
  border: 1px solid #9bb4cc;
  max-width: 200px;
  padding: 10px;
  // margin-left: 15px;
  border-radius: 5px;
  color: #9bb4cc;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 3.5px 8px;
  border-radius: 5px;
}

.datepicker-item-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid red;
  max-width: 185px;
  height: 40px;
  padding: 3.5px 8px;
  border-radius: 5px;
  background-color: white;
}

.datepicker-item > svg {
  margin-left: -30px;

  width: 18.75px;
  height: 18.75px;
}

.datepicker-item-error > svg {
  margin-left: -30px;

  width: 18.75px;
  height: 18.75px;
}

.datepicker-input {
  width: 120px;
  margin-right: 25px;
  outline: none;
  border: none;
  background-color: inherit;
  // color: #9bb4cc;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.datepicker-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #9bb4cc;
}

.react-datepicker__close-icon {
  display: none;
}

.react-datepicker-wrapper {
  // background-color: red;
  border: none;
}

.react-datepicker__input-container {
  border: none;
}

.react-datepicker__input-container > .datepicker-input {
  width: 93%;
  height: 19px;
  // border: 2px solid red;
  padding: 7px 0px 10px 0px;
}
