/** @format */

#chat-box {
  height: inherit;
  position: relative;
  // height: 40rem;

  #container {
    height: inherit;
  }

  .cs-message {
    padding-top: 10px;
  }

  .cs-message-list {
    background: #eeeae480;
  }

  #chat-message-container {
    width: 100%;
    overflow-x: hidden;
    height: 85%;
    max-height: 38rem;
  }

  #send-message {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    background-color: #f6f8fc;
  }

  .cs-message-separator {
    background-color: transparent;

    &::before,
    &::after {
      height: 0px;
    }
  }

  .cs-message__content:has(> .cs-message__image-content) {
    padding: 5px;
    padding-bottom: 0px;
  }

  .cs-message--incoming {
    .cs-message__content {
      background: #ebeff8;
    }

    .cs-message__content--text,
    .cs-message__content-pdf-name,
    .cs-message__content-pdf-icon,
    .cs-message__content--time {
      color: #33425b;
    }

    .cs-message__content--time {
      text-align: right;
    }
  }

  .cs-message--outgoing {
    .cs-message__content {
      background: #4350b3;
    }

    .cs-message__content--text,
    .cs-message__content-pdf-name,
    .cs-message__content-pdf-icon,
    .cs-message__content--time {
      color: #ffffff;
    }

    .cs-message__content--time {
      text-align: right;
    }
  }
}

.custom-resizeable-box {
  position: relative;
  & .react-resizable-handle {
    position: absolute;
    width: 20;
    height: 20;
    bottom: 0;
    right: 0;
    background: "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')";
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  }
}
