/** @format */

#active-patient-list,
#deactive-patient-list,
#draft-patient-list {
  .custom-table {
    .table-container {
      height: 70vh;
    }

    .table {
      .table-head-row {
        position: relative;
        th {
          background-color: #d5d5d6;

          color: #667185;

          font-family: Lato;
          font-size: 14px;
          font-weight: 600;
          line-height: 16.8px;
          letter-spacing: 0.01em;
        }

        .table-head-cell-action {
          text-align: center;
        }
      }

      .table-body-row {
        td {
          border-bottom-color: #9bb4cc;
        }
      }

      .MuiTableCell-root {
        padding: 8px;
      }
    }
  }
}
