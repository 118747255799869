/** @format */

.lab-reports-main {
  .title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.008em;
    color: black;
    padding: 15px 13px;
    border-bottom: 0.5px solid #8f8f8f;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;

    .heading {
      flex: 1;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .upload-report-main {
    padding: 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin: 30px 0;
    display: flex;
    align-items: center;

    .inner-main {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .button {
      display: flex;
      align-items: center;
      justify-self: center;
    }

    .button-icon {
      display: flex;
      align-items: center;
      justify-self: center;
      margin-left: 10px;
    }
  }

  .report-input-main {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;

    &:first-of-type {
      margin-top: 10px;
    }

    .input-line {
      margin-top: 6px;
      max-width: 150px;
    }

    .item-main {
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 10px;

      &:last-of-type {
        margin-right: 0;
      }

      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .datepicker {
    margin-top: 10px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .datepicker-title {
    margin-right: 6px;
  }

  .divider {
    margin: 10px 0;
  }

  form {
    div:has(> button[type='submit']) {
      text-align: right;
    }
  }
}
