/**
 * /* Sticky Div
 *
 * @format
 */

.rca-chart-sidebar {
  position: relative;
}

.vitals-history-inner-body-line-chart-date-picker {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Sticky Div */

#rca-chart {
  box-sizing: border-box;
  // border: 1px solid blue;

  min-height: max-content;

  .svg-element > g:nth-child(even) > rect {
    stroke: #9bb4cc;
    stroke-width: 0.4px;

    transform: translate(-50px, -0.5px);
    fill: #ffffff;
    width: 100%;
    // height: 164px;
  }

  .svg-element > g:nth-child(odd) > rect {
    stroke: #9bb4cc;
    stroke-width: 0.4px;

    transform: translate(-50px, 0px);
    fill: #f6f8fc;
    width: 100%;
    // height: 164px;
  }

  .svg-element > g:nth-child(1) > rect {
    stroke: none;
    fill: none;

    transform: translate(0px, 10px);
  }

  .axis {
    color: black;
    stroke: black;
    stroke-width: 0.2;
  }

  .axis--x {
    transform: translate(0px, -32px);
  }

  .axis--x text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    // font-size: 2em;
    font-size: 1.5em;
    line-height: 19px;
    letter-spacing: 0.01em;

    color: #667185;
  }

  .svg-element > .focus-element > .axis--y > .tick > text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    // font-size: 1.8em;
    font-size: 1.4em;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-align: right;

    transform: translate(10px, 0px);

    fill: #667185;
  }

  .rect--x {
    transform: translate(-50px, 1px);
    fill: #f1f2f3;
    height: 36px;
    width: 100%;
    stroke: none;
  }

  .current-date-rect {
    transform: translate(-19px, 8px);
    width: 39px;
    height: 22px;

    fill: #3e4685;
  }

  .current-date-rect ~ text {
    fill: white;
  }

  /* Hiding axis--x && axis--y Domain Line */
  .focus-element .axis--x .domain {
    display: none;
  }

  .focus-element .axis--y .domain {
    display: none;
  }

  /* Hiding axis--x axis--y Domain Line */

  /* Hiding all axis--x && axis--y ticks */
  .focus-element .axis--x line {
    display: none;
  }

  .focus-element .axis--y line {
    display: none;
  }

  .grid--x .tick text {
    display: none;
  }

  /* Hiding all axis--x && axis--y ticks */

  /* Css for vertical pipe */
  .vertical-line {
    stroke-width: 1.5;
    stroke: dodgerblue;
    fill: none;
    shape-rendering: geometricPrecision;
  }

  .horizontal-line {
    stroke-width: 1.5;
    stroke: dodgerblue;
    fill: none;
    transform: translate(-4px, 0px);
    shape-rendering: geometricPrecision;
  }

  /* Css for vertical pipe */

  // Hidding Grid Tick Values
  .grid--y > .tick > text {
    display: none;
  }
}

#rca-chart-sidebar {
  box-sizing: border-box;
  min-width: 218px;
}

/* #rca-chart-sidebar > div:nth-child(1) {
  position: sticky;
}

#rca-chart-sidebar > div:nth-child(2) {
  position: sticky;
} */

#rca-chart-sidebar > div {
  box-sizing: border-box;
}

#rca-chart {
  .svg-element > g:nth-child(even) > foreignObject > div {
    background-color: #ffffff;

    border-style: solid;
    border-color: #9bb4cc;
    border-width: 0px 0px 0.6px 0px;
  }

  .svg-element > g:nth-child(odd) > foreignObject > div {
    background-color: #f6f8fc;

    border-style: solid;
    border-color: #9bb4cc;
    border-width: 0px 0px 0.6px 0px;
  }

  .svg-element > g:nth-child(2) > foreignObject > div {
    border-style: solid;
    border-color: #9bb4cc;
    border-width: 0.1px 0px 0.6px 0px;
  }
}

/* #rca-chart-sidebar > div:nth-child(even) {
  background-color: #ffffff;

  border-style: solid;
  border-color: #9bb4cc;
  border-width: 0px 0px 0.6px 0px;
} */

/* #rca-chart-sidebar > div:nth-child(odd) {
  background-color: #f6f8fc;

  border-style: solid;
  border-color: #9bb4cc;
  border-width: 0px 0px 0.6px 0px;
} */

#rca-chart-sidebar > div:nth-child(1) {
  border-right: none;
  border-bottom: none;

  background-color: #f1f2f3;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#rca-chart {
  .tooltip-span {
    width: 100%;

    position: relative;
    cursor: pointer;
    display: inline-block;
  }

  .tooltip-span::before,
  .tooltip-span::after {
    position: absolute;
    display: none;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .tooltip-span::before {
    content: '';
    border: 5px solid transparent;
    z-index: 1001;
  }

  .tooltip-span::after {
    content: attr(data-tooltip);
    font-size: small;
    text-align: center;
    white-space: nowrap;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    background: rgb(250, 250, 250);
    color: black;
    z-index: 2;
  }

  .tooltip-span:hover::before,
  .tooltip-span:hover::after {
    display: block;
  }

  @keyframes tooltips-vert {
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  .tooltip-span:hover::before,
  .tooltip-span:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
  }
}

// Median Line
#rca-chart {
  .focus-element .d3-line-element > .line {
    // fill: none;
    // stroke: #3498db;
    stroke-width: 2.8;
  }
}

// Hiding stroke rect while zooming grid lines
#rca-chart {
  .focus-element > .d3-grid-element > .grid.grid--y > path {
    stroke: none;
  }
}

#rca-chart {
  #svg-icon-tooltip > div {
    * {
      background-color: unset;
    }
  }
}
