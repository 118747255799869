/** @format */

.kccq-main {
  .custom-table {
    .table-head-row {
      th {
        color: #33425b;
        font-size: 14px;
        font-weight: 600;

        border-bottom: 0px;

        padding: 0 0 5px 2px;
      }
    }

    .full-row {
      // height: 49px;

      td {
        padding: 0px;
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 1;
    }

    td {
      padding: 3px;
      padding-left: 5px;
      border: solid 1px #9bb4cc;
      border-style: none solid solid none;

      &.table-body-cell-no-data {
        border-style: solid;
        border-radius: 5px;
      }
    }

    tr:first-child td:first-child {
      border-top-left-radius: 4px;
    }

    tr:first-child td:last-child {
      border-top-right-radius: 4px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }

    tr:first-child td {
      border-top-style: solid;
    }
    tr td:first-child {
      border-left-style: solid;
    }

    tr:last-child td {
      border-bottom-style: solid;
    }
    tr td:last-child {
      border-bottom-style: solid;
    }
  }
}

.alerts-threshold {
  .custom-table {
    & > div {
      overflow-x: hidden;
    }
    .table-head-row {
      th {
        color: #33425b;
        font-size: 14px;
        font-weight: 600;

        border-bottom: 0px;

        padding: 0 0 5px 2px;
      }
    }

    td {
      padding: 10px 0 10px 0;
    }
  }
}
