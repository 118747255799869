/** @format */

#patient-profile-container {
  #patient-rule-item {
    .custom-table {
      .MuiFormLabel-root,
      .MuiFormHelperText-root {
        display: none;
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          border-bottom: none;
        }
      }
    }

    .MuiTableCell-root:has(.remove-cell) {
      display: none;
    }

    &.table-edit {
      .custom-table {
        .MuiTableCell-head {
          .MuiTypography-root {
            font-size: 12px !important;
          }
        }

        .MuiTableBody-root {
          .MuiTableCell-root {
            padding-top: 2px;
            padding-bottom: 2px;
            padding-left: 5px;
            padding-right: 5px;
          }

          .MuiSelect-select {
            padding: 8px;
          }
        }
      }
    }

    &.table-read {
      .custom-table {
        .MuiTableBody-root {
          .MuiTableCell-root {
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .MuiTableRow-root:first-child {
            .MuiTableCell-root {
              padding-top: 12px;
            }
          }

          .MuiTableRow-root:last-child {
            .MuiTableCell-root {
              padding-bottom: 12px;
            }
          }

          .MuiTableRow-root:only-child {
            .MuiTableCell-root {
              padding-top: 12px;
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

#patient-rule-item {
  .custom-table {
    .MuiFormLabel-root,
    .MuiFormHelperText-root {
      display: none;
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
  }

  .MuiTableCell-root:has(.remove-cell) {
    display: none;
  }

  &.table-edit {
    .custom-table {
      .MuiTableCell-head {
        .MuiTypography-root {
          // font-size: 12px !important;
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          padding-top: 2px;
          padding-bottom: 2px;
          // padding-left: 5px;
          // padding-right: 5px;
        }

        .MuiSelect-select {
          // padding: 8px;
        }
      }
    }
  }

  &.table-read {
    .custom-table {
      .MuiTableBody-root {
        .MuiTableCell-root {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        .MuiTableRow-root:first-child {
          .MuiTableCell-root {
            padding-top: 12px;
          }
        }

        .MuiTableRow-root:last-child {
          .MuiTableCell-root {
            padding-bottom: 12px;
          }
        }

        .MuiTableRow-root:only-child {
          .MuiTableCell-root {
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }
      }
    }
  }
}
