/** @format */

@mixin headerCssMixin {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 24px;
  letter-spacing: 0.008em;
  color: #3e4685;
}

#patient-ecg-container {
  // border: 0.6px solid #d5d5d6;
  // border-radius: 5px;

  // background-color: #fafbfc;

  .ecg-header-container {
    border-bottom: 0.6px solid #d5d5d6;

    .ecg-header-title {
      @include headerCssMixin;
    }

    .ecg-header-buttons {
      flex: 1;

      display: flex;
      justify-content: center;
      align-items: center;

      .ecg-date-picker {
        min-width: 200px;

        .datepicker-main {
          .datepicker-item {
            border: 1px solid #404681;
            border-radius: 10px;

            height: 34px;
          }
        }
      }

      .ecg-devices-menu {
        div > button {
          min-width: 150px;
          height: 34px;

          border: 1px solid #404681;
          border-radius: 10px;

          background-color: white;
          color: black;
        }
      }

      .ecg-diagnosis-menu {
        div > button {
          height: 34px;
          min-width: 200px;

          border: 1px solid #404681;
          border-radius: 10px;

          background-color: white;
          color: black;
        }
      }
    }
  }

  .ecgs-container > div {
    border: 0.6px solid #d5d5d6;
    border-radius: 5px;

    background-color: #ffff;

    .axis {
      // display: none;
    }
  }

  .ecgs-container > div > div:nth-child(2) {
    border: 0.6px solid #d5d5d6;

    background-color: #ffff;
  }
}

#patient-ecg-container {
  /* -----------------  ECG CSS  -------------------- */

  .ecgs-container {
    /* Grid */
    .grid line {
      stroke: #d9d9d9;
    }

    .tick:nth-of-type(n) {
      stroke-width: 0.3;
    }

    .tick:nth-of-type(5n + 1) {
      stroke-width: 0.8;
    }

    .grid path {
      stroke-width: 0;
    }

    /* Grid */

    /* Ecg Line */
    .lineUsers {
      fill: none;
      stroke-width: 1;

      stroke: #e24c30;
    }

    /* Ecg Line */
  }

  /* -----------------  ECG CSS  -------------------- */
}

// Ecg Scroll Bar
#patient-ecg-container {
  .ecgs-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .ecgs-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ecgs-container::-webkit-scrollbar {
    width: 0.4em;
  }

  .ecgs-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .ecgs-container::-webkit-scrollbar-thumb {
    background-color: #afc3d6;
  }
}

.ecgs-filters {
  .datepicker-main {
    .datepicker-item {
      padding: 0px;
      border: 0px;
    }

    .datepicker-item-error {
      min-width: max-content;
      border: 2px solid red;
      padding: 0.375rem 0rem 0.375rem 0rem;
    }

    .ecgs-date-picker {
      width: fit-content;
      min-width: 12vw;
      // height: 42px;
      padding: 0.375rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      appearance: none;
    }
  }
}
