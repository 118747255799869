/** @format */

.vitals-history-body {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d5d5d6;
  border-right: 1px solid #d5d5d6;

  /* Rca total graph height */
  // height: 40rem;
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.vitals-history-inner-body {
  display: flex;
}

.vitals-history-inner-body:last-of-type {
  /* border-bottom: 1px solid #d5d5d6; */
}

.vitals-history-inner-body-line-chart-headings {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vitals-history-inner-body-line-chart-date-picker {
  background-color: #f1f2f3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vitals-history-inner-body-line-chart-date-picker input,
.vitals-history-inner-body-line-chart-date-picker svg {
  color: black;
}

.rca-date-picker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.datepicker-item {
  height: 36px;
  margin: 0px;
}

.rca-date-picker input,
.rca-date-picker svg {
  color: black;
}

.datepicker-item > svg {
  cursor: default;
}

.react-datepicker__input-container > input {
  cursor: pointer;
}

.rca-week-next-previous {
  width: 80px;

  display: flex;
  justify-content: space-evenly;
}

.rca-week-next-previous-button {
  height: 36px;
}

.vitals-history-inner-body-line-charts {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  justify-content: space-around;
}

.vitals-history-inner-body-line-charts > div {
  height: 100%;
}

.vitals-history-inner-body-line-charts > div:nth-child(even) {
  background-color: rgb(218, 239, 248);
}

.vitals-history-body:nth-child(even) {
  background-color: #f6f8fc;
}

.vitals-history-body-side-info {
  min-width: auto;
  height: inherit;
  padding: 15px;

  border-right: 1px solid #d5d5d6;
  border-bottom: 1px solid #d5d5d6;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.vitals-history-body-side-info-current-value {
  min-width: max-content;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1;

  color: #33425b;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3em;
  line-height: 19px;
}

.vitals-history-body-side-info-current-value-icon-label {
  /* min-width: 140px; */

  font-size: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vitals-history-body-side-info-current-value-icon-label > svg {
  margin-right: 5px;
}

.vitals-history-body-side-info-vital-current-value {
  margin-left: 7px;
}

.vitals-history-body-side-info-unit {
  /* position: absolute; */
  /* top: 5px; */
  /* right: 5px; */

  margin-left: 5px;

  /* font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px; */
  /* margin-right: 9px; */
  /* color: #5c6a90; */
}

.vitals-history-body-side-info-current-value-percentage-diff {
  font-size: 0.7em;
}

.vitals-history-body-side-info-vital-reference-value-age-and-value {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vitals-history-body-side-info-vital-reference-value-age-and-value-icon-reference-age {
  display: flex;
  align-items: center;
}

.vitals-history-body-side-info-vital-reference-value-age-and-value-icon-reference-age > svg {
  margin-left: -4px;
}

.vitals-history-body-side-info-vital-reference-value-age-and-value-icon-reference-age-icon {
  margin-right: 4px;
}

.vitals-history-body-side-info-vital-reference-value-age-and-value-icon-reference-age-value {
  color: #33425b;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 1.1em;
  line-height: 19px;
}

.vitals-history-body-side-info-device {
  margin-left: -15px;
}

.vitals-history-body-vital-chart {
  flex: 1;
  /* width: 100%; */
}

.vital-history-settings-tab {
  display: flex;
}

.vital-history-settings-tab {
  padding: 20px;
  border-right: 1px solid #d5d5d6;
  border-left: 1px solid #d5d5d6;
  border-bottom: 1px solid #d5d5d6;
}

.vital-history-settings-tab-header {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.vital-history-settings-tab-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3e4685;
}

.vital-history-settings-tab-body {
  display: flex;
}

.vital-history-settings-tab-body-container {
  display: flex;
  align-items: center;
  margin-inline: 10px;
}

.vital-history-settings-tab-body-container-text {
  margin-right: 5px;
  color: #3e4685;
}

.vital-history-settings-tab-body-container-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9bb4cc;
  cursor: pointer;
}

.yaxis-range-main {
  display: flex;
  align-items: center;
}

.yaxis-range-input {
  max-width: 130px;
  margin-right: 5px;
}

.yaxis-range-input:first-of-type {
  margin-left: 5px;
}

.vital-history-settings-tab-alt-inner-main {
  display: flex;
}

.vital-history-settings-tab-alt {
  display: flex;
  flex-direction: column;
}

.vital-History-heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.008em;
  color: #333333;
  margin-left: 25px;
  margin-top: 10px;
}

.spb-heading {
  color: #000000;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
}

.units {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-left: 9px;
}

.vitals-history-tab-2 {
  height: 50vh;
}

#patient-activity-container {
  #custom-ag-table-container {
    height: 36rem !important;
  }
}

#rca-chart {
  #svg-icon-tooltip > div {
    * {
      background-color: unset;
    }
  }
}
